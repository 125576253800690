import { useCallback } from "react";
import useCustomReducer from "@livecasino/core/hooks/useCustomReducer";
import reducer, { ACTION_BUTTONS_ENABLE_STATE_KEYS, INITIAL_STATE } from "./reducer";
import useSignalR from "@livecasino/core/hooks/useSignalR";
import { CARD_GAMES_SOCKET_METHOD_INVOKES, PITBOSS_SERVER_URL, SOCKET_METHOD_INVOKES } from "shared/constants";
import * as types from "./types";
import queryString from "query-string";
import { ACTION_TYPES } from "../../../shared/constants";
import { BLACKJACK_SOCKET_METHODS } from "./constants";
import useEventsSharableFunctionality from "../../shared/useShareableFunctionality";

const CUSTOM_BLACKJACK_EVENTS = [
    {
        type: types.NEW_EVENT,
        listenerType: BLACKJACK_SOCKET_METHODS.NEW_EVENT
    },
    {
        type: types.CARD_DEALT,
        listenerType: CARD_GAMES_SOCKET_METHOD_INVOKES.CARD_DEALT
    },
    {
        type: types.BOTS_ACTIVATED,
        listenerType: BLACKJACK_SOCKET_METHODS.BOTS_ACTIVATED
    },
    {
        type: types.BOTS_DEACTIVATED,
        listenerType: BLACKJACK_SOCKET_METHODS.BOTS_DEACTIVATED
    }
];

const useActionsTabData = ({ getAsyncToken, onReceiveInfoMessage }) => {
    const queryObject = queryString.parse(location.search, { arrayFormat: "comma" });

    const [state, dispatch] = useCustomReducer(reducer, INITIAL_STATE, true);
    const connectionURL = PITBOSS_SERVER_URL + `/actions?tableId=${queryObject.tableId}`;
    const { hubConnection, connectionStatus, areListenersSetRef } = useSignalR({
        url: connectionURL,
        getAsyncToken
    });

    const handleCloseModal = useCallback(() => {
        dispatch({
            type: types.CLOSE_MODAL
        });
    }, [dispatch]);

    const handleRescanCards = useCallback(
        isRescanAll => {
            dispatch({
                type: types.DISABLE_BUTTON,
                payload: isRescanAll
                    ? ACTION_BUTTONS_ENABLE_STATE_KEYS.isRescanAllButtonDisabled
                    : ACTION_BUTTONS_ENABLE_STATE_KEYS.isRescanLastButtonDisabled
            });
            handleCloseModal();
            hubConnection.invoke(CARD_GAMES_SOCKET_METHOD_INVOKES.RESCAN, isRescanAll);
        },
        [hubConnection, dispatch, handleCloseModal]
    );

    const handleCancelGame = useCallback(
        fieldValues => {
            dispatch({
                type: types.DISABLE_BUTTON,
                payload: ACTION_BUTTONS_ENABLE_STATE_KEYS.isCancelButtonDisabled
            });

            hubConnection.invoke(SOCKET_METHOD_INVOKES.CANCEL_GAME, fieldValues);

            handleCloseModal();
        },
        [dispatch, hubConnection, handleCloseModal]
    );

    const handleUpdateGameStatus = useCallback(
        statusUpdateModel => {
            handleCloseModal();
            const { statusId, ...restModel } = statusUpdateModel;
            hubConnection.invoke(SOCKET_METHOD_INVOKES.UPDATE_TABLE_STATUS, statusId, restModel);
        },
        [hubConnection, handleCloseModal]
    );

    const handlePauseOrResumeGame = useCallback(
        statusUpdateModel => {
            handleCloseModal();
            dispatch({
                type: types.DISABLE_BUTTON,
                payload: ACTION_BUTTONS_ENABLE_STATE_KEYS.isPauseButtonDisabled
            });

            hubConnection.invoke(SOCKET_METHOD_INVOKES.UPDATE_GAME_STATUS, statusUpdateModel.StatusId);
        },
        [dispatch, hubConnection, handleCloseModal]
    );

    const handleGameSoundChange = useCallback(
        isMuted => {
            handleCloseModal();
            dispatch({
                type: types.DISABLE_BUTTON,
                payload: ACTION_BUTTONS_ENABLE_STATE_KEYS.isMuteButtonDisabled
            });
            hubConnection.invoke(SOCKET_METHOD_INVOKES.CHANGE_SOUND_STATUS, isMuted);
        },
        [dispatch, hubConnection, handleCloseModal]
    );

    const handleOpenModal = useCallback(
        type => {
            dispatch({
                type: types.OPEN_MODAL,
                payload: type
            });
        },
        [dispatch]
    );

    const handleClearCards = useCallback(() => {
        handleCloseModal();
        hubConnection.invoke(CARD_GAMES_SOCKET_METHOD_INVOKES.CLEAR_CARD);
    }, [hubConnection, handleCloseModal]);

    const onToggleBots = useCallback(() => {
        handleCloseModal();
        if (state.areBotsActivated) {
            hubConnection.invoke(SOCKET_METHOD_INVOKES.DEACTIVATE_BOTS);
        } else {
            hubConnection.invoke(SOCKET_METHOD_INVOKES.ACTIVATE_BOTS);
        }
    }, [hubConnection, handleCloseModal, state.areBotsActivated]);
    const handleMistakeClick = useCallback(
        (actionType, mistakeType) => {
            hubConnection.invoke(actionType, mistakeType);
        },
        [hubConnection]
    );
    const handleMistakeInfoModalSubmit = useCallback(
        modalInfo => {
            const { type, ...requestObj } = modalInfo;
            hubConnection.invoke(SOCKET_METHOD_INVOKES.POPUP_FINISH_ACTION, ACTION_TYPES[type], requestObj);
        },
        [hubConnection]
    );

    const handleCardChangePopupFinish = useCallback(
        ({ shufflerId, cardColors }) => {
            hubConnection.invoke(CARD_GAMES_SOCKET_METHOD_INVOKES.CARD_CHANGE_POPUP_FINISH_ACTION, {
                userId: shufflerId,
                cardColors
            });
        },
        [hubConnection]
    );

    const handleDealerLogin = useCallback(
        barcode => {
            hubConnection.invoke(SOCKET_METHOD_INVOKES.LOGIN_DEALER, barcode);
        },
        [hubConnection]
    );
    const handleCaptureThumbnail = useCallback(() => {
        hubConnection.invoke(SOCKET_METHOD_INVOKES.CAPTURE_THUMBNAIL);
    }, [hubConnection]);

    useEventsSharableFunctionality({
        gameName: "Blackjack",
        reducerEventTypes: types,
        hubConnection,
        dispatch,
        areListenersSetRef,
        connectionStatus,
        onReceiveInfoMessage,
        onDealerLogin: handleCloseModal,
        gameCustomListeners: CUSTOM_BLACKJACK_EVENTS
    });

    return {
        ...state,
        handleRescanCards,
        handleOpenModal,
        handleCloseModal,
        handleCancelGame,
        handleUpdateGameStatus,
        handleGameSoundChange,
        handleClearCards,
        handleMistakeClick,
        handlePauseOrResumeGame,
        handleMistakeInfoModalSubmit,
        handleCardChangePopupFinish,
        handleDealerLogin,
        handleCaptureThumbnail,
        onToggleBots
    };
};

export default useActionsTabData;
