import * as types from "./types";
import { getBlackjackButtonsInitialStates } from "hooks/ClassicBlackjackHooks/useClassicBlackjackActionsTabData/helpers";
import { updateActionsChange } from "../../../shared/helpers";

export const INITIAL_STATE = {
    gameConfig: {
        isGameMuted: true,
        isGamePaused: false,
        isGameActive: false
    },
    cards: [],
    events: [],
    actions: [],
    loading: true,
    closedStatusReasons: [],
    cancelGameStatusReasons: [],
    openModalType: null,

    // this properties are for buttons initial state , to make buttons while initialState response will be received
    pendingActions: {
        isClearButtonDisabled: true,
        isCancelButtonDisabled: true,
        isCloseButtonDisabled: true,
        isHistoryButtonDisabled: true,
        isMuteButtonDisabled: true,
        isPauseButtonDisabled: true,
        isRescanDisabled: true,
        isDealerLoginButtonDisabled: true
    },
    notificationMessage: null,
    cardColors: [],
    cardReasons: [],
    mistakeReasons: [],
    technicalReasons: [],
    shufflers: [],
    dealerInfos: [],
    isDealerLoggedIn: false,
    recordedStreamUrl: "",
    areBotsActivated: false
};

export const ACTION_BUTTONS_ENABLE_STATE_KEYS = {
    isClearButtonDisabled: "isClearButtonDisabled",
    isCancelButtonDisabled: "isCancelButtonDisabled",
    isCloseButtonDisabled: "isCloseButtonDisabled",
    isHistoryButtonDisabled: "isHistoryButtonDisabled",
    isMuteButtonDisabled: "isMuteButtonDisabled",
    isPauseButtonDisabled: "isPauseButtonDisabled",
    isRescanLastButtonDisabled: "isRescanLastButtonDisabled",
    isRescanAllButtonDisabled: "isRescanAllButtonDisabled"
};

export default function reducer(state, action) {
    const { type, payload } = action;

    switch (type) {
        case types.INITIAL_STATE: {
            const {
                gameConfig,
                cards,
                events,
                actions,
                closedStatusReasons,
                cancelGameStatusReasons,
                cardReasons,
                technicalReasons,
                mistakeReasons,
                dealerInfos,
                shufflersInfo,
                recordedStreamUrl,
                cardColors,
                areBotsActivated
            } = payload;

            const { isGamePaused, isGameActive } = gameConfig;
            const pendingActions = getBlackjackButtonsInitialStates(isGamePaused, isGameActive);
            return {
                ...state,
                gameConfig,
                cards,
                events,
                actions,
                loading: false,
                closedStatusReasons,
                cancelGameStatusReasons,
                pendingActions,
                cardReasons,
                technicalReasons,
                mistakeReasons,
                dealerInfos,
                recordedStreamUrl,
                shufflers: shufflersInfo,
                cardColors,
                areBotsActivated
            };
        }

        case types.CARD_DEALT: {
            const { time, boxId, card } = payload;
            const dealtCardInfo = { time, boxId, card };
            return { ...state, cards: [dealtCardInfo, ...state.cards] };
        }

        case types.NEW_EVENT: {
            const { action, card, cost, date, partner, player } = payload;
            const newEvent = { action, card, cost, date, partner, player };
            return { ...state, events: [newEvent, ...state.events] };
        }

        case types.NEW_ROUND_STARTED: {
            return { ...state, events: [], cards: [] };
        }

        case types.OPEN_MODAL: {
            const openModalType = payload;
            return { ...state, openModalType };
        }
        case types.CLOSE_MODAL: {
            return {
                ...state,
                openModalType: null
            };
        }

        case types.SET_DEALER_LOGGED_IN: {
            return { ...state, isDealerLoggedIn: payload };
        }

        case types.BOTS_ACTIVATED: {
            return { ...state, areBotsActivated: true };
        }

        case types.BOTS_DEACTIVATED: {
            return { ...state, areBotsActivated: false };
        }

        case types.TABLE_STATUS_CHANGED: {
            const {
                status: { isPaused, isActive }
            } = payload;
            const pendingActions = getBlackjackButtonsInitialStates(isPaused, isActive);

            return {
                ...state,
                gameConfig: { ...state.gameConfig, isGamePaused: isPaused, isGameActive: isActive },
                pendingActions
            };
        }

        case types.DISABLE_BUTTON: {
            const buttonType = action.payload;
            return {
                ...state,
                pendingActions: { ...state.pendingActions, [buttonType]: true }
            };
        }

        case types.GAME_SOUND_CHANGED: {
            const { isMuted: isGameMuted } = payload;
            const pendingActions = {
                ...state.pendingActions,
                [ACTION_BUTTONS_ENABLE_STATE_KEYS.isMuteButtonDisabled]: false
            };
            return { ...state, gameConfig: { ...state.gameConfig, isGameMuted }, pendingActions };
        }

        case types.ACTION_STATE_CHANGED: {
            const { type, state: actionState } = payload;
            const updatedActions = updateActionsChange(state.actions, { type, state: actionState });
            return { ...state, actions: updatedActions };
        }
    }
}
