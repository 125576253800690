export function createExpandedMetadata(data) {
    const result = [];
    let index = 0;
    for (const betType in data) {
        if (data.hasOwnProperty(betType)) {
            result.push({ betType, bet: data[betType], key: index++ });
        }
    }
    return result;
}

export function renderDealerName(dealerName, record) {
    const dealerNickname = record.dealerNickname;
    return `${dealerName} ${dealerNickname ? `(${dealerNickname})` : ""}`;
}
