import React from "react";
import MainModal from "./MainModal";
import PropTypes from "prop-types";
import { ACTIONS } from "../../../shared/constants";

const ActivateBots = ({ areBotsActivated = false, onClose, onClick }) => {
    let title = ACTIONS.ACTIVATE_BOTS;
    let message = "Are you sure you want to activate bots?";

    if (areBotsActivated) {
        title = ACTIONS.DEACTIVATE_BOTS;
        message = "Are you sure you want to deactivate bots?";
    }

    return (
        <MainModal onSubmit={onClick} onClose={onClose} cancelButtonText="No" confirmButtonText="Yes" title={title}>
            {message}
        </MainModal>
    );
};

ActivateBots.propTypes = {
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    areBotsActivated: PropTypes.bool
};

export default ActivateBots;
