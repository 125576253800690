import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./index.module.scss";
import { WowzaWebRTCPlayer } from "wowza-webrtc-player";

const LocalStreamPlayer = ({ config = null, volume, setIsPlayerInitialized, isPlayerInitialized }) => {
    const videoElementRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        if (config) {
            videoElementRef.current.muted = true;
            playerRef.current = new WowzaWebRTCPlayer(videoElementRef.current, config);
            playerRef.current.playRemote().catch(e => console.warn(e));
            return () => {
                playerRef.current?.stop();
                playerRef.current = null;
            };
        }
    }, [config]);

    useEffect(() => {
        if (videoElementRef.current && isPlayerInitialized) {
            videoElementRef.current.muted = volume === 0;
            videoElementRef.current.volume = volume;
        }
    }, [volume, isPlayerInitialized]);

    const handleLoadedMetadata = () => {
        if (typeof setIsPlayerInitialized === "function") {
            setIsPlayerInitialized(true);
        }
    };
    return (
        <div className={styles.PlayerWrapper}>
            <video className={styles.VideoPlayer} ref={videoElementRef} onLoadedMetadata={handleLoadedMetadata} />
        </div>
    );
};
LocalStreamPlayer.propTypes = {
    isPlayerInitialized: PropTypes.bool.isRequired,
    setIsPlayerInitialized: PropTypes.func,
    config: PropTypes.object,
    volume: PropTypes.number.isRequired
};

export default LocalStreamPlayer;
