import React, { useCallback, useState } from "react";
import { Form } from "antd";
import { checkButtonDisabled } from "shared/utils";
import PropTypes from "prop-types";
import styles from "components/Modals/styles.module.scss";
import { ACTIONS, TABLE_STATUSES } from "shared/constants";
import MainModal from "./MainModal";
import CommentWithReasonFields from "../../CommentWithReasonFields";

const CloseOrOpenTable = ({ gameConfig, onClose, reasons, onSubmit }) => {
    const { isGameActive } = gameConfig;
    const [form] = Form.useForm();
    const [isYesButtonDisabled, setIsYesButtonDisabled] = useState(true);

    const openTable = useCallback(() => {
        onSubmit({
            statusId: TABLE_STATUSES.Active
        });
    }, [onSubmit]);

    const closeTable = useCallback(() => {
        const formValues = form.getFieldsValue();
        const { reasonId, comment } = formValues;

        onSubmit({
            reasonId,
            comment,
            statusId: TABLE_STATUSES.Closed
        });
    }, [form, onSubmit]);

    const onValuesChange = useCallback(allValues => {
        const isYesButtonDisabled = checkButtonDisabled(allValues);

        setIsYesButtonDisabled(isYesButtonDisabled);
    }, []);

    const title = !isGameActive ? ACTIONS.OPEN_TABLE : ACTIONS.CLOSE_TABLE;
    const disabled = !isGameActive ? false : isYesButtonDisabled;

    return (
        <MainModal
            onSubmit={!isGameActive ? openTable : closeTable}
            onClose={onClose}
            cancelButtonText="No"
            confirmButtonText="Yes"
            title={title}
            confirmButtonDisabled={disabled}
        >
            {!isGameActive && <p>Are you sure you want to open this game?</p>}
            {isGameActive && (
                <>
                    <p>Are you sure you want to close this table?</p>
                    <div className={styles.FormContainer}>
                        <CommentWithReasonFields reasons={reasons} form={form} onValuesChange={onValuesChange} />
                    </div>
                </>
            )}
        </MainModal>
    );
};

CloseOrOpenTable.propTypes = {
    onClose: PropTypes.func.isRequired,
    gameConfig: PropTypes.shape({
        isGameMuted: PropTypes.bool,
        isGamePaused: PropTypes.bool,
        isGameActive: PropTypes.bool
    }).isRequired,
    reasons: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default CloseOrOpenTable;
