import React, { useMemo } from "react";
import ActivePlayers from "components/NavigationTabs/ClassicBlackJack/ActivePlayers";
import LastRounds from "../shared/LastRounds";
import { Tabs } from "antd";
import { BACKOFFICE_URL, TAB_KEYS } from "shared/constants";
import ActionsTab from "components/NavigationTabs/ClassicBlackJack/ActionsTab";
import ChatTab from "components/NavigationTabs/shared/ChatTab";
import CuttingCards from "../shared/CuttingCards";
import styles from "components/NavigationTabs/index.module.scss";
import { GAME_KINDS } from "../../../shared/constants";
import useLastRoundsTabData from "../../../hooks/shared/useLastRoundsTabData";
import { blackjackMetadata } from "./constants";

const REDIRECT_URL_BY_GAME_KIND = `${BACKOFFICE_URL}/reports/game-reports/${GAME_KINDS.BLACKJACK}`;

const ClassicBlackJack = ({ tableId, getAsyncToken, tableName, requestInstance }) => {
    const { lastRounds, loading } = useLastRoundsTabData({ getAsyncToken, gameKind: GAME_KINDS.BLACKJACK });

    const TAB_ITEMS = useMemo(() => {
        return [
            {
                key: TAB_KEYS.PLAYERS,
                label: TAB_KEYS.PLAYERS,
                children: <ActivePlayers getAsyncToken={getAsyncToken} />
            },
            {
                key: TAB_KEYS.CHAT,
                label: TAB_KEYS.CHAT,
                children: <ChatTab tableId={tableId} getAsyncToken={getAsyncToken} requestInstance={requestInstance} />
            },
            {
                key: TAB_KEYS.ACTIONS,
                label: TAB_KEYS.ACTIONS,
                children: <ActionsTab getAsyncToken={getAsyncToken} tableName={tableName} />
            },
            {
                key: TAB_KEYS.LAST_ROUNDS,
                label: TAB_KEYS.LAST_ROUNDS,
                children: (
                    <LastRounds
                        baseUrlToRedirect={REDIRECT_URL_BY_GAME_KIND}
                        lastRounds={lastRounds}
                        loading={loading}
                        metaData={blackjackMetadata}
                    />
                )
            },
            {
                key: TAB_KEYS.CUTTING_CARD,
                label: TAB_KEYS.CUTTING_CARD,
                children: <CuttingCards getAsyncToken={getAsyncToken} gameKind={GAME_KINDS.BLACKJACK} />
            }
        ];
    }, [getAsyncToken, tableId, requestInstance, tableName, lastRounds, loading]);

    return (
        <div className={styles.TabContainer}>
            <Tabs defaultActiveKey={TAB_KEYS.ACTIONS} items={TAB_ITEMS} destroyInactiveTabPane />
        </div>
    );
};

// ClassicBlackJack.propTypes = {
//     tableId: PropTypes.string.isRequired,
//     getAsyncToken: PropTypes.func.isRequired,
//     tableName: PropTypes.string.isRequired,
//     requestInstance: PropTypes.func.isRequired
// };

export default ClassicBlackJack;
