import React, { useCallback, useState, useMemo, useEffect, lazy, Suspense } from "react";
import PitBossChat from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/Chat/v2/PitBossChat";
import Modal from "@livecasino/core/v2/components/Modal";
import BlockUser from "components/Modals/ModalContent/BlockUser";
import PropTypes from "prop-types";
import { CHAT_URL, LANGUAGES_URL, WARNINGS_URL } from "shared/constants";
import styles from "./index.module.scss";
import { showNotification, onCloseHandler } from "@livecasino/core/v2/components/Notification";
import InfoMessage from "@livecasino/core/v2/components/InfoMessage";
import { getBlockUserDurations, openPlayerReportInNewTab } from "../../../../shared/utils";
import useChat from "@livecasino/core/hooks/useChat/v2";
import Loader from "@livecasino/core/v2/components/Loader";
import useSendWarningData from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/Chat/v2/PitBossChat/hooks/useSendWarningData";
import { notification } from "antd";

const SendWarningContentLazy = lazy(
    () => import("@livecasino/core/v2/Backoffice_DMS_Pitboss/components/Chat/v2/PitBossChat/SendWarningContent")
);

const SEND_WARNINGS_ERROR_TITLE = "Send Warnings";

const ChatTab = ({ tableId, getAsyncToken, requestInstance }) => {
    const [selectedMessageId, setSelectedMessageId] = useState(null);

    const pitBossChatRef = React.useRef(null);

    const onReceiveInfoMessage = useCallback(message => {
        showNotification(<InfoMessage message={message} transparent={false} onClose={onCloseHandler} />);
    }, []);

    const onBlock = useCallback(data => {
        const { message } = data;
        setSelectedMessageId(message.id);
    }, []);

    const handleCloseModal = useCallback(() => {
        setSelectedMessageId(null);
    }, []);

    const handlePlayerProfileClick = useCallback(messageObject => {
        openPlayerReportInNewTab(messageObject.senderId);
    }, []);

    const onErrorOfGetWarningResources = useCallback(err => {
        notification.error({ description: err.message, message: SEND_WARNINGS_ERROR_TITLE });
    }, []);

    const chatServerURL = CHAT_URL + `?tableId=${tableId}`;

    const {
        messages,
        sendFromPitBoss: sendMessage,
        getPitbossInitialState,
        removeMessage,
        editMessage,
        connectionStatus,
        userStatusUpdateOptions,
        updateUserChatStatus,
        getPitbossPageOptions,
        hubConnection,
        areListenersSet,
        unHideMessage,
        showToAll,
        isMessagesLoading
    } = useChat({
        getAsyncToken,
        chatServerURL,
        onInfoMessageCB: onReceiveInfoMessage
    });

    const {
        playerInfoToSendWarning,
        warningTypeSelectOptions,
        warningLanguageSelectOptions,
        selectedLanguageCode: selectedLanguageCodeForWarningModal,
        selectedWarningType: selectedTypeForWarningModal,
        onSelectChange,
        sendWarning,
        onClickOfModalClose,
        onClickOfSendWarningAction
    } = useSendWarningData({
        languagesUrl: LANGUAGES_URL,
        warningsUrl: WARNINGS_URL,
        requestInstance,
        sendMessage,
        onError: onErrorOfGetWarningResources
    });

    useEffect(() => {
        if (!hubConnection || !connectionStatus.isConnected || !areListenersSet) {
            return;
        }
        getPitbossInitialState(50);
        getPitbossPageOptions();
    }, [hubConnection, connectionStatus.isConnected, getPitbossInitialState, getPitbossPageOptions, areListenersSet]);

    const handleSubmit = useCallback(
        values => {
            const { note, playerChatBlockType, blockDurationSeconds } = values;
            handleCloseModal();
            const userStatusUpdateModel = {
                note,
                playerChatBlockType,
                blockDurationSeconds,
                messageId: selectedMessageId
            };
            pitBossChatRef.current?.block(userStatusUpdateModel);
        },
        [selectedMessageId, handleCloseModal]
    );

    const blockDurations = useMemo(
        () => getBlockUserDurations(userStatusUpdateOptions.blockDurations),
        [userStatusUpdateOptions.blockDurations]
    );

    return (
        <div className={styles.ChatContainer}>
            {isMessagesLoading && <div className={styles.Loading}>{<Loader />}</div>}
            {!isMessagesLoading && (
                <PitBossChat
                    showToAll={showToAll}
                    unHideMessage={unHideMessage}
                    onOpenPlayerProfile={handlePlayerProfileClick}
                    isConnecting={connectionStatus.pending}
                    onBlock={onBlock}
                    onClickOfSendWarningAction={onClickOfSendWarningAction}
                    chatRef={pitBossChatRef}
                    messages={messages}
                    sendMessage={sendMessage}
                    removeMessage={removeMessage}
                    editMessage={editMessage}
                    userStatusUpdateOptions={userStatusUpdateOptions}
                    updateUserChatStatus={updateUserChatStatus}
                />
            )}
            <Modal open={playerInfoToSendWarning} onBackdropClick={onClickOfModalClose}>
                <Suspense fallback={null}>
                    <SendWarningContentLazy
                        onClose={onClickOfModalClose}
                        onSend={sendWarning}
                        languages={warningLanguageSelectOptions}
                        types={warningTypeSelectOptions}
                        onChange={onSelectChange}
                        languageCode={selectedLanguageCodeForWarningModal}
                        warningType={selectedTypeForWarningModal}
                    />
                </Suspense>
            </Modal>
            <Modal open={Boolean(selectedMessageId)}>
                <BlockUser
                    onClose={handleCloseModal}
                    onSubmit={handleSubmit}
                    blockReasons={userStatusUpdateOptions.blockReasons}
                    blockDurations={blockDurations}
                />
            </Modal>
        </div>
    );
};

ChatTab.propTypes = {
    tableId: PropTypes.string.isRequired,
    requestInstance: PropTypes.func.isRequired,
    getAsyncToken: PropTypes.func.isRequired
};

export default ChatTab;
