import React, { useMemo, Suspense } from "react";
import queryString from "query-string";
import PitBossLobby from "components/PitBossLobby";
import useWindowSize from "@livecasino/core/hooks/useWindowSize";
import DMSPitBoss from "components/DMSPitBoss";
import GlobalStyle2 from "@livecasino/core/v2/GlobalStyle";
import { AUTH_URL, theme } from "shared/constants";
import { FULL_SCREEN_WIDTH } from "@livecasino/core/constants/screenResolutions";
import useAuth from "@livecasino/core/hooks/useAuth";
import calculateFontSize from "@livecasino/core/helpers/calculateFontSize";
import ErrorBoundary from "@livecasino/core/v2/components/ErrorBoundary";
import SliderStyles from "@livecasino/core/v2/components/Slider/SliderStyles";
import TooltipStyles from "@livecasino/core/v2/components/Tooltip/Styles";
import { setDebugMode } from "@livecasino/core/helpers/logger";
import { NotificationContainer } from "@livecasino/core/v2/components/Notification";
import "./index.css";
import { ConfigProvider } from "antd";
import RecordedStreamPage from "./components/RecordedStreamPage";

setDebugMode(true);

let {
    tableId,
    games: tableIds = [],
    token,
    refreshToken,
    gameType,
    tableName,
    recordedStreamUrl,
    gameName
} = queryString.parse(location.search, {
    arrayFormat: "comma",
    decode: false
});

let title = document.querySelector("title");
const decodedTableName = decodeURIComponent(tableName);
const decodedGameName = decodeURIComponent(gameName);

if (title && tableName && tableId) {
    title.innerText = `${tableId}/${decodedGameName}`;
}

if (typeof tableIds === "string") {
    tableIds = [tableIds];
}

function App() {
    const { getAsyncToken, getAsyncTokens, requestInstance } = useAuth({
        token,
        refreshToken,
        frameUrl: AUTH_URL,
        keyPrefix: "PITBOSS"
    });
    const { width } = useWindowSize({ delay: 0 });
    const fontSize = useMemo(() => {
        return calculateFontSize({
            width,
            baseWidth: FULL_SCREEN_WIDTH
        });
    }, [width]);

    const isRecordedStreamVisible = Boolean(recordedStreamUrl);
    const isDMSPitBossVisible = !isRecordedStreamVisible && Boolean(tableId);
    const isPitbossLobbyVisible = !isRecordedStreamVisible && !isDMSPitBossVisible;
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ConfigProvider theme={theme}>
                <div className={"AppWrapper"} style={{ fontSize }}>
                    <NotificationContainer limit={3} />
                    <GlobalStyle2 />
                    <TooltipStyles toolTipColor="var(--lc-pitboss__black_900-61)" />
                    <SliderStyles />
                    <ErrorBoundary>
                        {isRecordedStreamVisible && (
                            <RecordedStreamPage recordedStreamUrl={recordedStreamUrl} tableName={decodedTableName} />
                        )}
                        {isDMSPitBossVisible && (
                            <DMSPitBoss
                                tableId={tableId}
                                getAsyncToken={getAsyncToken}
                                gameType={gameType}
                                getAsyncTokens={getAsyncTokens}
                                token={token}
                                refreshToken={refreshToken}
                                requestInstance={requestInstance}
                            />
                        )}
                        {isPitbossLobbyVisible && (
                            <PitBossLobby
                                tableIds={tableIds}
                                getAsyncTokens={getAsyncTokens}
                                getAsyncToken={getAsyncToken}
                            />
                        )}
                    </ErrorBoundary>
                </div>
            </ConfigProvider>
        </Suspense>
    );
}

export default App;
