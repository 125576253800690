import React from "react";
import ControlButton from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/ControlButton";
import ButtonWithState from "@livecasino/core/v2/Backoffice_DMS_Pitboss/components/DMSButtonWithState";
import CircledPhone from "../../../../../svgs/CircledPhone";
import styles from "./index.module.scss";
import PropTypes from "prop-types";
import {
    ACTION_STATE_LABELS,
    ACTION_STATES,
    MODAL_TYPES,
    GAME_KINDS,
    ACTION_TYPES,
    ACTIONS,
    ACTION_MODAL_TYPE
} from "shared/constants";
import { handleOpenTableHistory } from "../../../../../shared/utils";
import { openRecordedStreamInNewTab } from "../../../../../shared/helpers";

const Actions = ({
    gameConfig,
    actions,
    onActionButtonsClick = null,
    onMistakesClick = null,
    buttonsPending,
    recordedStreamUrl,
    tableName,
    onCaptureThumbnail,
    areBotsActivated = false
}) => {
    const { isGameMuted, isGamePaused, isGameActive } = gameConfig;
    const {
        isClearButtonDisabled,
        isCancelButtonDisabled,
        isCloseButtonDisabled,
        isHistoryButtonDisabled,
        isMuteButtonDisabled,
        isDealerLoginButtonDisabled,
        isPauseButtonDisabled
    } = buttonsPending;

    const handleTableHistoryClick = () => handleOpenTableHistory(GAME_KINDS.BLACKJACK);

    const talkAction = actions.find(
        action => action.type === ACTION_TYPES.TALK && action.state !== ACTION_STATES.FINISH
    );
    const activeIconColor =
        talkAction?.state === ACTION_STATES.ACTIVE ? "var(--lc-pitboss__green_500)" : "var(--lc-pitboss__red_500)";
    const handlePhoneClick = () => {
        const actionType =
            talkAction?.state === ACTION_STATES.ACTIVE ? ACTION_TYPES.PROCESSING_ACTION : ACTION_TYPES.FINISH_ACTION;
        onMistakesClick(actionType, talkAction.type);
    };

    const muteBtnBackground = isGameMuted ? "var(--lc-pitboss__yellow_800)" : "var(--lc-pitboss__gray_600)";
    const pauseBtnBackground = isGamePaused ? "var(--lc-pitboss__green_500)" : "var(--lc-pitboss__gray_600)";
    const activateBotsBtnBackground = areBotsActivated
        ? "var(--lc-pitboss__yellow_800)"
        : "var(--lc-pitboss__gray_600)";

    const openTableBtnText = isGameActive ? ACTIONS.CLOSE_TABLE : ACTIONS.OPEN_TABLE;
    const muteBtnText = isGameMuted ? ACTIONS.UNMUTE_FOR_USERS : ACTIONS.MUTE_FOR_USERS;
    const pauseBtnText = isGamePaused ? ACTIONS.RESUME : ACTIONS.PAUSE;
    const activateBotsBtnText = areBotsActivated ? ACTIONS.DEACTIVATE_BOTS : ACTIONS.ACTIVATE_BOTS;

    return (
        <div className={styles.ActionMistakes}>
            <div className={styles.ActionsContainer}>
                <span className={styles.ActionTitle}>Actions</span>
                <div className={styles.ActionButtons}>
                    <ControlButton
                        onClick={() => onActionButtonsClick(MODAL_TYPES.CLEAR_CARDS)}
                        disabled={isClearButtonDisabled}
                    >
                        {ACTIONS.CLEAR_CARD}
                    </ControlButton>
                    <ControlButton
                        onClick={() => onActionButtonsClick(MODAL_TYPES.CANCEL_GAME)}
                        disabled={isCancelButtonDisabled}
                    >
                        {ACTIONS.CANCEL_GAME}
                    </ControlButton>
                    <ControlButton
                        onClick={() => onActionButtonsClick(MODAL_TYPES.CLOSE_TABLE)}
                        disabled={isCloseButtonDisabled}
                    >
                        {openTableBtnText}
                    </ControlButton>
                    <ControlButton onClick={handleTableHistoryClick} disabled={isHistoryButtonDisabled}>
                        {ACTIONS.TABLE_HISTORY}
                    </ControlButton>
                    <ControlButton
                        backgroundColor={muteBtnBackground}
                        onClick={() => onActionButtonsClick(MODAL_TYPES.MUTE_FOR_USERS)}
                        disabled={isMuteButtonDisabled}
                    >
                        {muteBtnText}
                    </ControlButton>
                    <ControlButton
                        backgroundColor={pauseBtnBackground}
                        onClick={() => onActionButtonsClick(MODAL_TYPES.PAUSE_GAME)}
                        disabled={isPauseButtonDisabled}
                    >
                        {pauseBtnText}
                    </ControlButton>
                    <ControlButton
                        onClick={() => onActionButtonsClick(MODAL_TYPES.DEALER_LOGIN)}
                        disabled={isDealerLoginButtonDisabled}
                    >
                        {ACTIONS.DEALER_LOGIN}
                    </ControlButton>
                    <ControlButton onClick={onCaptureThumbnail}>{ACTIONS.CAPTURE_THUMBNAIL}</ControlButton>
                    <ControlButton
                        onClick={() =>
                            openRecordedStreamInNewTab({
                                recordedStreamUrl,
                                tableName
                            })
                        }
                        disabled={!recordedStreamUrl}
                    >
                        {ACTIONS.LIVE_STREAM}
                    </ControlButton>
                    <ControlButton
                        backgroundColor={activateBotsBtnBackground}
                        onClick={() => onActionButtonsClick(MODAL_TYPES.ACTIVATE_BOTS)}
                    >
                        {activateBotsBtnText}
                    </ControlButton>
                </div>
            </div>
            <div className={styles.MistakesContainer}>
                <div className={styles.LeftColumn}>
                    {actions.map(action => {
                        if (action.state === ACTION_STATES.FINISH || action.type === ACTION_TYPES.TALK) {
                            return null;
                        }
                        const handleActionClick = () => {
                            if (
                                (action.type === ACTION_TYPES.MISTAKE ||
                                    action.type === ACTION_TYPES.TECHNICAL ||
                                    action.type === ACTION_TYPES.CARD ||
                                    action.type === ACTION_TYPES.CARD_CHANGE) &&
                                action.state === ACTION_STATES.PROCESSING
                            ) {
                                onActionButtonsClick(ACTION_MODAL_TYPE[action.type]);
                            } else {
                                const actionType =
                                    action.state === ACTION_STATES.ACTIVE
                                        ? ACTION_TYPES.PROCESSING_ACTION
                                        : ACTION_TYPES.FINISH_ACTION;
                                onMistakesClick(actionType, action.type);
                            }
                        };

                        return (
                            <div key={action.type}>
                                <span className={styles.Title}>{action.type}</span>
                                <div className={styles.MistakeButton}>
                                    <ButtonWithState
                                        actionType={action.type}
                                        actionState={action.state}
                                        btnText={ACTION_STATE_LABELS[action.state]}
                                        onClick={handleActionClick}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={styles.RightColumn}>
                    {talkAction && (
                        <div className={styles.PhoneIconWrapper} onClick={handlePhoneClick}>
                            <CircledPhone backgroundColor={activeIconColor} />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Actions.propTypes = {
    gameConfig: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    onActionButtonsClick: PropTypes.func,
    onMistakesClick: PropTypes.func,
    buttonsPending: PropTypes.object.isRequired,
    recordedStreamUrl: PropTypes.string.isRequired,
    tableName: PropTypes.string.isRequired,
    onCaptureThumbnail: PropTypes.func.isRequired,
    areBotsActivated: PropTypes.bool
};

export default Actions;
